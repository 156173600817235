import React from 'react'
import { Section } from '../Common'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import htmr from 'htmr'
import { useMeetTheTeam } from '../../GraphQL/Queries'

const StaticBookBanner = () => {
    const data = useMeetTheTeam()

    const content = {
        title: data.acf_bookCTA.title,
        image: data.acf_bookCTA.image.sourceUrlSharp.childImageSharp.fluid,
        alt: data.acf_bookCTA.image.altText,
    }

    return (
        <Section>
            <div className="book-section">
                <div className="book-section__image-container">
                    <Img
                        fluid={content.image}
                        alt={content.alt}
                        className="book-section__image"
                    />
                </div>
                <div className="book-section__info">
                    <h1 className="book-section__info-title">
                        {htmr(content.title)}
                    </h1>
                    <Link
                        to="/book-consultation"
                        className="book-section__info-button"
                    >
                        BOOK NOW{' '}
                        <i className="icon icon--white icon--arrow-right" />
                    </Link>
                </div>
            </div>
        </Section>
    )
}

export default StaticBookBanner
