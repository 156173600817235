import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import { Container, Section } from '../components/Common'
import Img from 'gatsby-image'
import htmr from 'htmr'
import StaticBookBanner from '../components/BookBanner/StaticBookBanner'
import { BlogCard } from '../components/Blog'
import FooterSocial from '../components/Footer/FooterSocial'

const blogPost = ({
    data: {
        wpgraphql: { post, social },
    },
}) => {
    const { acf_blog, acf_blogPostMeta, title, categories, id } = post

    const blogContent = {
        content: acf_blog.blogContent,
        excerpt: acf_blog.blogExcerpt,
        image: acf_blog.blogImage.sourceUrlSharp.childImageSharp.fluid,
        meta: {
            category: acf_blogPostMeta.category.name,
        },
        social: {
            twitter: social.acf_social.twitter,
            linkedin: social.acf_social.linkedin,
            instagram: social.acf_social.instagram,
            facebook: social.acf_social.facebook,
        },
    }

    const related = {
        allRelated: categories.nodes[0].posts.edges,
    }

    return (
        <Layout pageClass="blog-post" noHero>
            <SEO title={title} description={blogContent.excerpt} />
            <Section>
                <Container fullBleed>
                    <div className="blog-post__header">
                        <span className="blog-post__header-label">
                            {blogContent.meta.category}
                        </span>
                        <h1 className="headline headline--h1 blog-post__header-title">
                            {title}
                        </h1>
                        <div className="divider divider--sm divider--thick"></div>
                    </div>
                    <div className="blog-post__content">
                        <Img
                            fluid={blogContent.image}
                            className="blog-post__image"
                        />
                        <div className="blog-post__content-block">
                            {htmr(blogContent.content)}
                        </div>
                        <FooterSocial
                            facebook={blogContent.social.facebook}
                            instagram={blogContent.social.instagram}
                            linkedIn={blogContent.social.linkedin}
                            twitter={blogContent.social.twitter}
                        />
                    </div>
                </Container>
            </Section>
            <Section>
                <Container>
                    <div className="related-section">
                        <div className="related-section__header">
                            <h2 className="headline headline--h2">
                                RELATED POSTS
                            </h2>
                            <div className="divider divider--full divider--thin"></div>
                        </div>
                        <div className="related-section__cards">
                            {related.allRelated &&
                                related.allRelated.map(post => {
                                    if (post.node.id !== id) {
                                        return (
                                            <BlogCard
                                                title={post.node.title}
                                                category={
                                                    post.node.acf_blogPostMeta
                                                        .category.name
                                                }
                                                image={
                                                    post.node.acf_blog.blogImage
                                                        .sourceUrlSharp
                                                        .childImageSharp.fluid
                                                }
                                                link={`/blog/${post.node.slug}`}
                                                key={post.node.slug}
                                            />
                                        )
                                    }
                                })}
                        </div>
                    </div>
                </Container>
            </Section>
            <div className="wp-video" style={{ visibility: 'hidden' }}></div>
            <StaticBookBanner />
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        wpgraphql {
            post: postBy(slug: $slug) {
                id
                title
                acf_blog {
                    blogContent
                    blogExcerpt
                    blogImage {
                        sourceUrl
                        sourceUrlSharp {
                            childImageSharp {
                                fluid(quality: 100, maxWidth: 1440) {
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    sizes
                                }
                            }
                        }
                    }
                }
                acf_blogPostMeta {
                    category {
                        slug
                        name
                    }
                    mostPopular {
                        uri
                        count
                    }
                }
                categories {
                    nodes {
                        name
                        posts(first: 3) {
                            edges {
                                node {
                                    id
                                    title
                                    slug
                                    acf_blogPostMeta {
                                        category {
                                            name
                                        }
                                    }
                                    acf_blog {
                                        blogImage {
                                            sourceUrl
                                            sourceUrlSharp {
                                                childImageSharp {
                                                    fluid(
                                                        quality: 100
                                                        maxWidth: 1440
                                                    ) {
                                                        base64
                                                        aspectRatio
                                                        src
                                                        srcSet
                                                        sizes
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            social {
                acf_social {
                    twitter
                    linkedin
                    instagram
                    facebook
                }
            }
        }
    }
`

export default blogPost
